<template>
  <app-collapse-item :is-visible="true" title="Creative Briefing">
    <b-row>
      <b-col cols="12" md="4">
        <HeroDisplayText
          id="artwork-per-month"
          :value="creative.creative_briefing_link"
          label="Creative Briefing Link"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroDisplayText
          id="artwork-per-month"
          :value="creative.artwork_per_month"
          label="Do you commit amount of artwork per month"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroDisplayText
          id="product"
          :value="creative.product"
          label="Product, service, branch information document"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroDisplayText
          id="any-faq"
          :value="creative.any_faq"
          label="Any FAQ questions to add to the flow?"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroDisplayText
          id="any-promotion"
          :value="creative.any_promotion"
          label="Any promotion offer to show the user?"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroDisplayText
          id="pieces"
          :value="creative.pieces.toString()"
          label="How many pieces?"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroDisplayText
          id="lead-data"
          :value="creative.lead_item"
          label="Lead data requirements(email, phone)?"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'

export default {
  components: {
    AppCollapseItem,
    HeroDisplayText,
  },
  props: {
    creative: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
}
</script>
