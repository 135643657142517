export default class OpportunityHistory {
  constructor(item = {}) {
    return {
      account_id: item.account_id || '',
      opportunity_id: item.id || '',
      name: item.name || '',
      stage: item.stage_name || '',
      action: item.action || '',
      duration: item.number_of_cycles || '',
      amount: item.amount,
      owner: item.account_owner_name,
    }
  }
}
