<template>
  <b-modal
    id="import-planner-template-modal"
    ref="import-planner-template-modal"
    title="Import Planner Template"
    centered
    hide-footer
    no-close-on-backdrop
    @show="doResetModal"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <b-form @submit.stop.prevent>
      <b-row>
        <b-col>
          <b-form-file
            v-model="uploadedFile"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".xlsx"
            class="mb-1"
          />
        </b-col>
      </b-row>

      <!-- !! Footer -->
      <hr>
      <b-row>
        <b-col cols="6" sm="3">
          <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
            Back
          </HeroButtonAction>
        </b-col>

        <template v-if="!passValidation">
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction
              type="button"
              variant="primary"
              :disabled="!uploadedFile"
              @click="doValidate"
            >
              Validate
            </HeroButtonAction>
          </b-col>
        </template>

        <template v-if="passValidation">
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction
              type="button"
              variant="primary"
              @click="doImport"
            >
              Import
            </HeroButtonAction>
          </b-col>
        </template>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BRow,
  BCol,
  BFormFile,
} from 'bootstrap-vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BFormFile,
    HeroButtonAction,
  },
  props: {
    campaignId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      uploadedFile: null,
      passValidation: false,
    }
  },
  methods: {
    doCloseModal() {
      this.$refs['import-planner-template-modal'].hide()
      this.$emit('success')
    },

    doResetModal() {
      this.uploadedFile = null
      this.passValidation = false
    },

    // eslint-disable-next-line consistent-return
    async doValidate() {
      let alertResult

      if (!this.uploadedFile) {
        alertResult = await this.$swal({ ...SweetAlert.warning, text: 'Please choose a file to import.' })
        return null
      }

      this.showOverlay = true

      const token       = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      try {
        const formData = new FormData()

        formData.append('uploadedFile', this.uploadedFile)

        console.dir(this.campaignId)

        const response = await axios.post(`/planner-template/validate/${this.campaignId}`, formData, axiosConfig)

        alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

        if (alertResult.value) {
          this.passValidation = response.data['pass-validation']
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.errorCycleOverlapping, html: ErrorService.extractError(error) })
      }

      this.showOverlay = false
    },

    async doImport() {
      let alertResult

      this.showOverlay = true

      const token       = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      try {
        const response = await axios.post(`/planner-template/import/${this.campaignId}`, null, axiosConfig)

        alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

        if (alertResult.value) {
          this.$emit('importSuccess', true)
          this.doResetModal()
          this.doCloseModal()
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }

      this.showOverlay = false
    },
  },
}
</script>
