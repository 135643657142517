<template>
  <b-modal
    id="allocate-budget-modal"
    ref="allocate-budget-modal"
    :title="transferMode === 'Transfer' ? 'Allocate Budget - Transfer Budget From Opportunity' : 'Allocate Budget - Return Budget To Opportunity'"
    size="lg"
    centered
    hide-footer
    no-close-on-backdrop
    @shown="doLoadData"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer">
      <b-form @submit.stop.prevent>

        <!-- !! Table Header -->
        <b-row>
          <b-col class="mt-1" cols="12">
            <HeroInputText
              id="filter"
              v-model="tableConfig.filter"
              placeholder="Search"
              @input="doTableFilter(500)"
            />
          </b-col>
        </b-row>

        <!-- !! Table -->
        <b-row class="mb-1">
          <b-col cols="12" style="height: calc(40vh); overflow: hidden;">
            <b-table
              show-empty
              striped
              sticky-header="100%"
              :bordered="true"
              :small="true"
              :responsive="true"
              :items="items"
              :fields="tableConfig.fields"
              :per-page="0"
              :sort-by="tableConfig.sortBy"
              :sort-direction="tableConfig.sortDirection"
              :no-sort-reset="true"
              :no-local-sorting="true"
              @sort-changed="doTableSort"
            >

              <template #cell(action)="data">
                <div style="width: 10px;">
                  <b-form-radio
                    v-model="opportunityId"
                    :value="data.item.id"
                    @change="selectOpportunity(data.item)"
                  />
                </div>
              </template>

              <template #cell(name)="data">
                <div style="text-align: left;">
                  {{ data.item.name }}
                </div>
              </template>

              <template #cell(type)="data">
                <div style="text-align: center;">
                  {{ data.item.type }}
                </div>
              </template>

              <template #cell(amount)="data">
                <div style="text-align: right;">
                  {{ moneyService.defaultMoneyFormat(data.item.amount) }}
                </div>
              </template>

              <template #cell(amount_remaining)="data">
                <div style="text-align: right;">
                  {{ moneyService.defaultMoneyFormat(data.item.amount_remaining) }}
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <!-- !! Mode Selector -->
        <b-row class="mb-2">
          <b-col cols="6">
            <HeroButtonAction
              type="button"
              :variant="transferMode === 'Transfer' ? 'primary' : 'outline-primary'"
              @click="transferMode='Transfer'; calculateUpdatedRemaining()"
            >
              Transfer From Opportunity
            </HeroButtonAction>
          </b-col>
          <b-col cols="6">
            <HeroButtonAction
              type="button"
              :variant="transferMode === 'Return' ? 'primary' : 'outline-primary'"
              @click="transferMode='Return'; calculateUpdatedRemaining()"
            >
              Return To Opportunity
            </HeroButtonAction>
          </b-col>
        </b-row>

        <hr>

        <!-- !! Edit Section -->
        <b-row v-if="opportunity.id === ''" class="mb-2">
          <b-col cols="12">
            <p style="text-align: center;">
              <strong>Please select a opportunity.</strong>
            </p>
          </b-col>
        </b-row>

        <b-row v-if="opportunity.id !== ''">
          <b-col cols="12">
            <HeroVueSelect
              id="wallet_budget_id"
              v-model="walletBudgetId"
              label="Budget"
              column="6"
              :clearable="false"
              :options="this.$store.getters['SourceWalletBudgetOptions/getOptions']"
              @input="calculateUpdatedRemaining"
            />
          </b-col>
          <b-col cols="12">
            <HeroInputText
              id="opportunity-remain"
              v-model="opportunity.amount_remaining"
              label="Opportunity Remaining"
              column="6"
              :readonly="true"
              custom-style="text-align: right;"
            />
          </b-col>
          <b-col cols="12">
            <HeroInputText
              id="campaign-remaining"
              v-model="campaignRemaining"
              label="Campaign Remaining"
              column="6"
              :readonly="true"
              custom-style="text-align: right;"
            />
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="transfer-amount"
              :rules="transferAmountRules"
            >
              <HeroInputText
                id="amount"
                v-model="transferAmount"
                :label="transferMode === 'Transfer' ? 'Transfer Amount' : 'Return Amount'"
                column="6"
                :readonly="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
                custom-style="text-align: right;"
                @input="calculateUpdatedRemaining"
              />
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="new-campaign-remaining"
              :rules="newCampaingRemainingRules"
            >
              <HeroInputText
                id="new-campaign-remaining"
                v-model="newCampaignRemaining"
                label="New Campaign Remaining"
                column="6"
                :readonly="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
                custom-style="text-align: right;"
              />
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="new-opportunity-remaining"
              :rules="newOpportunityRemainingRules"
            >
              <HeroInputText
                id="new-opportunity-remaining"
                v-model="newOpportunityRemaining"
                label="New Opportunity Remaining"
                column="6"
                :readonly="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
                custom-style="text-align: right;"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" sm="4">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" offset-sm="4" sm="4">
            <HeroButtonAction
              type="button"
              variant="primary"
              :disabled="opportunity.id === '' || moneyService.isZero(transferAmount)"
              @click="validationForm"
            >
              Transfer
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import moment from 'moment'
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import SweetAlert from '@/services/SweetAlert'
import Toastification from '@/services/Toastification'
import MoneyService from '@/services/MoneyService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
  BTable,
  BFormRadio,
} from 'bootstrap-vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
  between,
  numeric,
} from '@validations'

export default {
  name: 'AllocateBudgetModal',
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    BTable,
    BFormRadio,
    HeroInputText,
    HeroVueSelect,
    HeroButtonAction,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    campaignId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      opportunityId: '',
      opportunity: {
        id: '',
        name: '',
        type: 'Opportunity',
        amount: '0',
        amount_remaining: '0',
      },

      // Transfer คือ เอาเงินจาก Opportunity มาเติมให้ Campaign
      // Return คือ เอาเงินจาก Campaign กลับไปให้ Opportunity
      transferMode: 'Transfer',

      walletBudgetId: '',

      transferAmount: '0',
      transferAmountRules: 'required',

      campaignRemaining: '0',

      newCampaignRemaining: '0',
      newCampaingRemainingRules: 'required',

      newOpportunityRemaining: '0',
      newOpportunityRemainingRules: 'required',

      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: '',
            key: 'action',
            sortable: false,
          },
          {
            label: 'Opportunity',
            key: 'name',
            sortable: false,
          },
          {
            label: 'Type',
            key: 'type',
            sortable: false,
          },
          {
            label: 'Amount',
            key: 'amount',
            sortable: false,
          },
          {
            label: 'Remaining',
            key: 'amount_remaining',
            sortable: false,
          },
        ],
      },

      // Service
      moneyService: MoneyService,

      // Overlay
      showOverlay: false,

      // Validation
      required,
      between,
      numeric,
    }
  },
  methods: {
    doCloseModal() {
      this.opportunityId = ''
      this.opportunity = {
        id: '',
        name: '',
        type: 'Opportunity',
        amount: '0',
        amount_remaining: '0',
      }

      this.walletBudgetId = ''

      this.transferAmount = 0
      this.campaignRemaining = 0
      this.newCampaignRemaining = 0
      this.newOpportunityRemaining = 0

      this.transferAmountRules = 'required'
      this.newCampaingRemainingRules = 'required'
      this.newOpportunityRemainingRules = 'required'

      this.$refs['allocate-budget-modal'].hide()
    },

    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      this.showOverlay = true

      await this.$store.commit('SourceWalletBudgetOptions/setSourceId', this.campaignId)
      await this.$store.commit('SourceWalletBudgetOptions/setSourceType', 'Campaign')
      await this.$store.commit('SourceWalletBudgetOptions/setDoUpdate', true)
      await this.$store.dispatch('SourceWalletBudgetOptions/fetchOptions')

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      // หารายการ Opportunity
      try {
        const {
          filter,
        } = this.tableConfig

        const response = await axios.get(`wallet-family/get-related-opportunities/${this.campaignId}?filter=${filter}`, axiosConfig)
        this.items = response.data.data
      } catch (error) {
        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }

        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }

        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }

      // หาค่า Campaign Balance
      try {
        const body = {
          objectId: this.campaignId,
          objectType: 'Campaign',
        }

        const response = await axios.post('wallet-family/get-balance/', body, axiosConfig)

        this.campaignRemaining = response.data.balance
      } catch (error) {
        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }

        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }

        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }

      this.showOverlay = false
    },

    // eslint-disable-next-line consistent-return
    async validationForm() {
      let alertResult
      let toastObject

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)
      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        if (this.walletBudgetId === '') {
          toastObject = Toastification.getContentError('Please choose one budget.')
          this.$toast(toastObject)
          return false
        }

        if (this.updatedRemaining < 0) {
          toastObject = Toastification.getContentError('Updated opportunity remaining must higher than 0')
          this.$toast(toastObject)
          return false
        }

        this.showOverlay = true

        const body = {
          action: this.transferMode,
          budget: this.transferAmount,
          opportunity_id: this.opportunity.id,
          wallet_budget_id: this.walletBudgetId,
        }

        try {
          const response = await axios.post(`wallet-family/allocate-budget/${this.campaignId}`, body, axiosConfig)

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          if (alertResult.value) {
            this.$refs['allocate-budget-modal'].hide()
            this.$emit('success')
          }
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }

        this.showOverlay = false
      }
    },

    selectOpportunity(object) {
      this.opportunity = object
    },

    calculateUpdatedRemaining() {
      const remainAmount = this.$store.getters['SourceWalletBudgetOptions/getRemainAmount'](this.walletBudgetId)

      if (this.transferMode === 'Transfer') {
        this.transferAmountRules = `required|numeric|between:0,${this.opportunity.amount_remaining}`

        this.newCampaignRemaining = parseFloat(this.campaignRemaining) + parseFloat(this.transferAmount)
        this.newOpportunityRemaining = parseFloat(this.opportunity.amount_remaining) - parseFloat(this.transferAmount)
      }

      if (this.transferMode === 'Return') {
        // this.transferAmountRules = `required|numeric|between:1,${this.campaignRemaining}`
        this.transferAmountRules = `required|numeric|between:1,${remainAmount}`

        this.newCampaignRemaining = parseFloat(this.campaignRemaining) - parseFloat(this.transferAmount)
        this.newOpportunityRemaining = parseFloat(this.opportunity.amount_remaining) + parseFloat(this.transferAmount)
      }
    },
  },
}
</script>
