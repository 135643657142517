<template>
  <div>
    <b-row v-if="products != null" class="mt-2">
      <b-table-simple id="media-plan-table" hover caption-top striped responsive bordered class="media-cycles" style="text-align: center; font-weight: normal; font-size: 12px; letter-spacing: 0px;">
        <b-thead head-variant="dark">
          <b-tr>
            <b-th v-for="(column, index) in columns" :key="index" style="vertical-align: middle; font-size: 11px;" :style="`min-width: ${column.width}`">
              {{ column.label }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-for="(product, index) in products" :key="index">
          <b-tr>
            <b-th style="text-align: left; background-color:grey; color: white;" colspan="12">
              {{ product.product }}
            </b-th>
          </b-tr>
          <b-tr v-for="(childData, childIndex) in product.children" :key="childIndex">
            <b-td v-if="!childData.is_total" class="text-center align-item-center">
              <b-badge
                v-b-tooltip.hover.v-primary
                :title="`Start Date: ${childData.product_start_date} End Date: ${childData.product_end_date}`"
                :variant="getBadgeVariant(childData.product_status_name)"
                class="mb-1"
              >
                {{ childData.product_status_name }}
              </b-badge>
            </b-td>
            <b-td v-if="!childData.is_total" class="text-left">
              <div class="span span:hover">
                <img
                  :src="getImage(childData.product_family_name)"
                  alt=""
                  width="15"
                  height="15"
                >
                <a v-if="childData.is_media && (
                  childData.product_family_name === 'Hero Google' ||
                  childData.product_family_name === 'Hero Facebook' ||
                  childData.product_family_name === 'Hero Youtube' ||
                  childData.product_family_name === 'Hero Instagram' ||
                  childData.product_family_name === 'Hero TikTok')" v-b-modal.cycle-product-campaign href="javascript:void(0)" @click="$emit('cycleProduct', childData)"
                >
                  {{ childData.name }}
                </a>
                <a v-else-if="childData.is_channel &&
                  childData.product_family_name !== 'Hero Google' &&
                  childData.product_family_name !== 'Hero Facebook' &&
                  childData.product_family_name !== 'Hero Youtube' &&
                  childData.product_family_name !== 'Hero Instagram' &&
                  childData.product_family_name !== 'Hero TikTok'" v-b-modal.cycle-product-channel href="javascript:void(0)" @click="$emit('cycleProduct', childData)"
                >
                  {{ childData.name }}
                </a>
                <span v-else>
                  {{ childData.name }}
                </span>
              </div>
            </b-td>
            <b-td v-else-if="childData.is_total && childData.product_family_id == ''" colspan="2" variant="primary">
              Media Total
            </b-td>
            <b-td v-else variant="secondary" colspan="2">
              Total
            </b-td>
            <b-td v-if="!childData.is_total" class="text-right" style="min-width: 90px;">
              {{ formatMoney(childData.net_budget) }}
            </b-td>
            <b-td v-else-if="childData.is_total && childData.product_family_id" class="text-right" variant="secondary">
              {{ formatMoney(childData.net_budget) }}
            </b-td>
            <b-td v-else class="text-right" variant="primary">
              {{ formatMoney(childData.net_budget) }}
            </b-td>
            <b-td v-if="!childData.is_total" class="text-right">
              {{ formatMoney(childData.ads_budget) }}
            </b-td>
            <b-td v-else-if="childData.is_total && childData.product_family_id" class="text-right" variant="secondary">
              {{ formatMoney(childData.ads_budget) }}
            </b-td>
            <b-td v-else class="text-right" variant="primary">
              {{ formatMoney(childData.ads_budget) }}
            </b-td>
            <b-td v-if="!childData.is_total" class="text-right">
              {{ formatMoney(childData.management_fee) }}
            </b-td>
            <b-td v-if="!childData.is_total" class="text-right">
              {{ formatMoney(childData.kpi) }}
            </b-td>
            <b-td v-if="!childData.is_total">
              <div style="width: 100px;">
                {{ getKPIUnitName(childData.kpi_unit_id) }}
              </div>
            </b-td>
            <b-td v-if="!childData.is_total" class="text-right">
              <div style="width: 100px;">
                {{ formatMoney(childData.cost_per_unit) }}
              </div>
            </b-td>
            <b-td
              v-if="childData.is_total && childData.product_family_id"
              class="text-center"
              colspan="7"
              variant="secondary"
            >
              {{ leadsbyproductfamilyid(childData.product_family_id, index) }}
            </b-td>
            <b-td
              v-else-if="childData.is_total && childData.product_family_id == ''"
              class="text-center"
              colspan="7"
              variant="primary"
            >
              {{ leadsbyproductfamilyid(childData.product_family_id, index) }}
            </b-td>
            <b-td v-if="!childData.is_total" role="cell">
              <div class="span span:hover">
                {{ childData.comment }}
              </div>
            </b-td>
            <b-td v-if="!childData.is_total" class="text-right">
              <div v-if="childData.did_phone">
                {{ childData.did_phone }}
              </div>
              <div v-else>
                {{ childData.direct_channel_id }}
              </div>
            </b-td>
            <!-- !! Wallet Budget Name / HeroAI Campaign Id -->
            <b-td v-if="!childData.is_total" role="cell">
              <div>
                {{ childData.wallet_budget_name }}
              </div>
            </b-td>
          </b-tr>
          <b-tr v-if="product.product == 'Media Products'">
            <b-td style="text-align: left;" colspan="11">
              <span>
                Media Plan: <a :href="getMediaPlanLink" target="blank">{{ getMediaPlanLink }}</a>
              </span>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td variant="primary" colspan="2">
              Grand Total
            </b-td>
            <b-td variant="primary" class="text-right">
              {{ formatMoney(getGrandTotal.net_budget) }}
            </b-td>
            <b-td variant="primary" class="text-right">
              {{ formatMoney(getGrandTotal.ads_budget) }}
            </b-td>
            <b-td class="text-center" colspan="7" variant="primary">
              {{ getGrandTotal.kpi_summary }}
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-row>
  </div>
</template>
<script>
import DataFormatService from '@/services/DataFormatService'
import Constant from '@/utils/Constant'

export default {
  props: {
    products: {
      type: Array,
      default: null,
    },
    kpiOptions: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Product',
          field: 'product',
          width: '350px',
        },
        {
          label: 'Net Budget (THB)',
          field: 'net_budget',
        },
        {
          label: 'Ads.Budget (THB)',
          field: 'ads_budget',
        },
        {
          label: 'Management Fee(+Buffer)',
          field: 'management_fee',
        },
        {
          label: 'KPI Per Cycle',
          field: 'kpi',
        },
        {
          label: 'KPI Unit',
          field: 'kpi_unit_id',
        },
        {
          label: 'CP KPI Unit (THB)',
          field: 'cost_per_unit',
        },
        {
          label: 'Comment',
          field: 'comment',
        },
        {
          label: 'Direct Channel/ DID Number',
          field: 'direct_channel',
        },
        {
          label: 'HeroAI Campaign ID',
          field: 'wallet_budget_name',
        },
      ],
    }
  },
  computed: {
    getGrandTotal() {
      const ProductList = []
      const GrandTotalArray = []
      this.products.forEach(products => {
        const childrens = products.children.filter(x => x.is_total === false)
        childrens.forEach(childData => {
          const KPIUnitIndex = GrandTotalArray.findIndex(x => x.id === childData.kpi_unit_id)
          const KPIPerCycle = parseInt(childData.kpi, 10)
          if (KPIUnitIndex > -1) {
            GrandTotalArray[KPIUnitIndex].value += KPIPerCycle
          } else {
            GrandTotalArray.push({
              id: childData.kpi_unit_id,
              value: KPIPerCycle,
            })
          }
          ProductList.push(childData)
        })
      })
      const KPISummary = []
      let kpiSummaryText = ''
      if (GrandTotalArray && GrandTotalArray.length > 0) {
        GrandTotalArray.forEach(kpidata => {
          const kpiUnitName = this.kpiOptions.filter(x => x.value === kpidata.id)
          if (kpiUnitName && kpiUnitName.length > 0) {
            KPISummary.push(`${this.addNumberComma(kpidata.value)} ${kpiUnitName[0].text}`)
          }
        })
        kpiSummaryText = KPISummary.join(' || ')
      }
      const TotalNetBudget = ProductList.map(x => parseFloat(x.net_budget))
      const TotalNet = TotalNetBudget.reduce((partialSum, a) => partialSum + a, 0)
      const TotalAdsBudget = ProductList.map(x => parseFloat(x.ads_budget))
      const TotalAds = TotalAdsBudget.reduce((partialSum, a) => partialSum + a, 0)
      return { net_budget: TotalNet, ads_budget: TotalAds, kpi_summary: kpiSummaryText }
    },
    getMediaPlanLink() {
      return this.$store.state.heroAiCampaign.media_briefing.media_plan_link || ''
    },
  },
  created() {
    this.formatMoney = DataFormatService.formatMoney
  },
  methods: {
    getBadgeVariant(cycleStatus) {
      if (cycleStatus.toLowerCase() === Constant.productStatusOptions.Active.toLowerCase()) {
        return 'success'
      }
      if (cycleStatus.toLowerCase() === Constant.productStatusOptions.Paused.toLowerCase()) {
        return 'warning'
      }
      if (cycleStatus.toLowerCase() === Constant.productStatusOptions.Onboarding.toLowerCase()) {
        return 'info'
      }
      if (cycleStatus.toLowerCase() === Constant.productStatusOptions.OnHold.toLowerCase())  {
        return 'primary'
      }

      return 'danger'
    },
    getDate(value) {
      return DataFormatService.formatUsaDate(value)
    },
    getGrandTotalForMedia() {
      const ProductList = []
      const GrandTotalArray = []
      this.products.forEach(products => {
        const childrens = products.children.filter(x => x.is_total === false && x.ismedia === true)
        childrens.forEach(childData => {
          const KPIUnitIndex = GrandTotalArray.findIndex(x => x.id === childData.kpi_unit_id)
          const KPIPerCycle = parseInt(childData.kpi, 10)
          if (KPIUnitIndex > -1) {
            GrandTotalArray[KPIUnitIndex].value += KPIPerCycle
          } else {
            GrandTotalArray.push({
              id: childData.kpi_unit_id,
              value: KPIPerCycle,
            })
          }
          ProductList.push(childData)
        })
      })
      const KPISummary = []
      let kpiSummaryText = ''
      if (GrandTotalArray && GrandTotalArray.length > 0) {
        GrandTotalArray.forEach(kpidata => {
          const kpiUnitName = this.kpiOptions.filter(x => x.value === kpidata.id)
          if (kpiUnitName && kpiUnitName.length > 0) {
            KPISummary.push(`${this.addNumberComma(kpidata.value)} ${kpiUnitName[0].text}`)
          }
        })
        kpiSummaryText = KPISummary.join(' || ')
      }
      return kpiSummaryText
    },
    getImage(type) {
      const images = require.context('@/assets/images/social-icons', false, /\.png$/)
      let imageURL = images('./Hero.png')
      if (type === 'Hero Google' || type === 'AdWords') {
        imageURL = images('./google.png')
      } else if (type === 'Facebook' || type === 'Hero Facebook') {
        imageURL = images('./facebook.png')
      } else if (type === 'Line' || type === 'Line Ads' || type === 'Hero Line') {
        imageURL = images('./line.png')
      } else if (type === 'Hero Call Tracking' || type === 'Call Tracking') {
        imageURL = images('./mobile-phone.png')
      } else if (type === 'Direct') {
        imageURL = images('./landing-page.png')
      } else if (type === 'Instagram' || type === 'Hero Instagram') {
        imageURL = images('./instagram.png')
      } else if (type === 'YouTube' || type === 'Hero Youtube') {
        imageURL = images('./youtube.png')
      } else if (type === 'Messenger' || type === 'Hero Chatbot') {
        imageURL = images('./messenger.png')
      } else if (type === 'Email' || type === 'Hero Mail') {
        imageURL = images('./gmail.png')
      } else if (type === 'Linkedin') {
        imageURL = images('./linkedin.png')
      } else if (type === 'SMS') {
        imageURL = images('./sms.png')
      } else if (type === 'Taboola') {
        imageURL = images('./taboola.png')
      } else if (type === 'Twitter') {
        imageURL = images('./twitter.png')
      } else if (type === 'Whatsapp') {
        imageURL = images('./whatsapp.png')
      }
      return imageURL
    },
    getKPIUnitName(kpiUnitId) {
      const kpiUnitName = this.kpiOptions.filter(x => x.value === kpiUnitId)
      if (kpiUnitName && kpiUnitName.length > 0) {
        return kpiUnitName[0].text
      }
      return ''
    },
    leadsbyproductfamilyid(productfamilyid, mediaindex) {
      if (productfamilyid !== '') {
        const mediaProducts = this.products[mediaindex].children.filter(x => x.is_total === false && x.product_family_id === productfamilyid)
        const TotalArray = []
        mediaProducts.forEach(childData => {
          const KPIUnitIndex = TotalArray.findIndex(x => x.id === childData.kpi_unit_id)
          const KPIPerCycle = parseInt(childData.kpi, 10)

          if (KPIUnitIndex > -1) {
            TotalArray[KPIUnitIndex].value += KPIPerCycle
          } else {
            TotalArray.push({
              id: childData.kpi_unit_id,
              value: KPIPerCycle,
            })
          }
        })
        const KPISummary = []
        let kpiSummaryText = ''
        if (TotalArray && TotalArray.length > 0) {
          TotalArray.forEach(kpidata => {
            const kpiUnitName = this.kpiOptions.filter(x => x.value === kpidata.id)
            if (kpiUnitName && kpiUnitName.length > 0) {
              KPISummary.push(`${this.addNumberComma(kpidata.value)} ${kpiUnitName[0].text}`)
            }
          })
          kpiSummaryText = KPISummary.join(' || ')
        }
        return kpiSummaryText
      }
      return this.getGrandTotalForMedia()
    },

    addNumberComma(value) {
      return value ? (+value).toLocaleString('en-US') : 0
    },
  },
}
</script>
<style>
  #media-plan-table .span {
    min-width: 350px !important; /* can be 100% ellipsis will happen when contents exceed it */
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  #media-plan-table .span:hover {
    white-space: normal;
    /* or:
    width: auto
    */
  }
  #media-plan-table .tooltip.b-tooltip {
    width: 160px !important;
  }

  [dir] .media-cycles .table th, [dir] .media-cycles .table td {
    padding: 5px !important;
  }
</style>
