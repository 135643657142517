<template>
  <app-collapse-item :is-visible="true" title="Campaign Overview">
    <template v-slot:action>
      <b-row>
        <b-button
          v-if="(isEdit && $store.state.heroAiCampaign.historyState === 0)"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="ml-0 mr-1 h-25 mb-0"
          @click="saveCamapignData()"
        >
          Save
        </b-button>
        <b-button
          v-if="(isEdit && $store.state.heroAiCampaign.historyState === 0)"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="ml-0 mr-2 h-25 mb-0"
          @click="isEdit = false"
        >
          Cancel
        </b-button>
        <b-button
          v-if="($can('Edit', 'Campaigns > Campaign > Campaign Overview') && !isEdit && $store.state.heroAiCampaign.historyState === 0)"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="ml-0 h-25 mr-2 mb-0"
          @click="isEdit = !isEdit"
        >
          Edit
        </b-button>
        <b-form-group
          v-if="$store.state.heroAiCampaign.historyState > 0"
          label-for="campaign-history-name"
          label-cols-md="0"
          class="ml-0 mr-1 margin mb-0"
        >
          <template v-slot:label>
            <span style="font-size: 1rem; font-weight: 600; line-height: 34px;">
              {{ $store.state.heroAiCampaign.historySelected.campaignHistoryName }}
            </span>
          </template>
        </b-form-group>
        <b-button
          v-if="$store.state.heroAiCampaign.historyState > 0"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="success"
          class="ml-0 h-25 mr-2 mb-0"
          @click="($store.state.heroAiCampaign.historyState = 0)"
        >
          &lt; Back to current plan
        </b-button>
      </b-row>
    </template>
    <b-tabs v-if="dataBind" pills justified>
      <b-tab v-if="campaign.campaign_overview" active title="Campaign Overview" :title-item-class="tabClass.campaign">
        <validation-observer ref="campaignOverviewRules" tag="form">
          <CampaignOverview
            v-if="isEdit"
            :campaign-status-options="campaignStatusOptions"
            :business-objective-option="businessObjectiveOption"
            :campaign="campaign.campaign_overview"
            :is-edit-mode="true"
          />
          <CampaignOverviewDisplay v-else :campaign="campaign_overview" />
        </validation-observer>
      </b-tab>
      <validation-observer ref="briefingRules" tag="form">
        <Brief
          :is-edit="isEdit"
          :tab-class="tabClass.brief"
          :landing-page-type-options="landingPageTypeOptions"
          :language-options="languageOptions"
          :report-options="reportOptions"
          :creative="campaign.creative_briefing"
          :web-solution="campaign.web_solution_briefing"
          :media="campaign.media_briefing"
          :chatbot="campaign.chatbot"
          :document="campaign.document_section"
          :seo-requirements="campaign.seo_requirements_section"
          :system-detail="campaign.system_details"
        />
      </validation-observer>
      <b-tab v-if="campaign.staff" title="Staff" :title-item-class="tabClass.staff">
        <validation-observer ref="staffRules" tag="form">
          <StaffAssignment
            v-if="isEdit"
            :am-options="amOptions"
            :optimizer-options="optimizerOptions"
            :copy-writer-options="copyWriterOptions"
            :designer-options="designerOptions"
            :system-support-options="systemSupportOptions"
            :data-analyst-options="dataAnalystOptions"
            :influencer-options="influencerOptions"
            :digital-associate-options="digitalAssociateOptions"
            :sale-options="saleOptions"
            :viewers-options="viewersOptions"
            :point-options="pointOptions"
            :ad-platform-options="adPlatformOptions"
            :zero-point-id="zeroPointId"
            :staff-assignment="campaign.staff"
          />
          <StaffAssignmentDisplay v-else :staff="campaign.staff" />
        </validation-observer>
      </b-tab>
      <b-tab title="Opportunity Overview" title-item-class="hightlight-tab">
        <OpportunityOverview v-if="campaign.opportunity_overview" :opportunity="campaign.opportunity_overview" />
      </b-tab>
    </b-tabs>
  </app-collapse-item>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import AxiosService from '@/services/AxiosService'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Constant from '@/utils/Constant'
import CampaignDetail from '@/models/Campaigns/Detail'
import CampaignOverviewModel from '@/models/Campaigns/CampaignOverview'
import CreativeBriefingInfoModel from '@/models/Campaigns/CreativeBriefing'
import WebSolutionBriefingModel from '@/models/Campaigns/WebSolutionBriefing'
import MediaBrief from '@/models/Campaigns/MediaBriefing'
import ChatbotSectionInfo from '@/models/Campaigns/ChatbotSection'
import DocSection from '@/models/Campaigns/DocumentSection'
import StaffAssign from '@/models/Campaigns/StaffAssignment'
import Store from '@/store/index'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import { DATE_PICKER_FORMAT } from '@/utils/Format'
import CampaignDropdownFactory from '@/factories/CampaignDropdown'
import moment from 'moment'
import { ValidationObserver } from 'vee-validate'
import Opportunity from '@/models/Opportunity'
import SEORequirementsSection from '@/models/Campaigns/SEORequirementsSection'
import OpportunityOverview from './campaign-overview/OpportunityOverview.vue'
import CampaignOverviewDisplay from './campaign-overview/CampaignOverview.vue'
import CampaignOverview from '../../create/CampaignOverview.vue'
import Brief from './campaign-overview/Brief.vue'
import StaffAssignmentDisplay from './campaign-overview/StaffAssignment.vue'
import StaffAssignment from '../../create/StaffAssignment.vue'

export default {
  components: {
    AppCollapseItem,
    OpportunityOverview,
    CampaignOverview,
    CampaignOverviewDisplay,
    Brief,
    StaffAssignment,
    StaffAssignmentDisplay,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    autoSyncSuccess: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      loading: false,
      collapseType: 'border',
      isEdit: false,
      campaign: {},
      campaign_overview: new CampaignOverviewModel({}),
      creative_briefing_info: new CreativeBriefingInfoModel({}),
      web_sol_briefing: new WebSolutionBriefingModel({}),
      media_briefing: new MediaBrief({}),
      chatbot_section_info: new ChatbotSectionInfo({}),
      document_section: new DocSection({}),
      seoRequirementsSection: new SEORequirementsSection({}),
      staff_assignment: new StaffAssign({}),
      opportunity_id: '',
      opportunity: {},
      dataBind: false,
      tabClass: {
        campaign: 'hightlight-tab',
        brief: 'hightlight-tab',
        staff: 'hightlight-tab',
      },
      campaignStatusOptions: [],
      businessObjectiveOption: [],
      landingPageTypeOptions: [],
      languageOptions: [],
      reportOptions: [],
      amOptions: [],
      optimizerOptions: [],
      copyWriterOptions: [],
      designerOptions: [],
      systemSupportOptions: [],
      dataAnalystOptions: [],
      influencerOptions: [],
      digitalAssociateOptions: [],
      saleOptions: [],
      viewersOptions: [],
      pointOptions: [],
      adPlatformOptions: [],
      zeroPointId: '',
    }
  },
  computed: {
    historyState() {
      return this.$store.state.heroAiCampaign.historyState
    },
  },
  watch: {
    historyState: {
      handler() {
        this.isEdit = false
        this.getCampaign()
      },
    },

    autoSyncSuccess: {
      async handler() {
        this.isEdit = false
        await this.getCampaign()
      },
    },
  },
  created() {
    this.getCampaignStatusDropdownList()
    this.getBusinessObjectivesDropdownList()

    this.getLanguageDropdownList()
    this.getLandingpgTypeDropdownList()

    this.getReportSystemDropdownList()

    this.getUserDropdownList('users')
    this.getUserPointDropdownList()
    this.getAdPlatformDropdownList()
  },
  async mounted() {
    if (this.$store.state.heroAiCampaign.historyState > 0) {
      this.$store.state.heroAiCampaign.historyState = 0
      this.$store.state.heroAiCampaign.historySelected = {}
    } else {
      await this.getCampaign()
    }
  },
  methods: {
    async getOpportunityDetail() {
      this.isLoading = true
      try {
        const qparam = {
          opportunity_id: this.$store.state.heroAiCampaign.opportunity_overview.opt_id,
        }
        const response = await AxiosService.get(Constant.apiURL.salesforceOpportunityDetail, qparam)
        this.opportunity = new Opportunity(response.data)
        this.$store.state.heroAiCampaign.mediaopportunity = this.opportunity
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
      this.isLoading = false
    },

    async getCampaign() {
      await this.resetStore()
      this.loading = true
      try {
        let url = `${Constant.apiURL.campaign.global}/${this.$route.params.id}`
        if (this.$store.state.heroAiCampaign.historyState > 0) {
          const HistorySelected = this.$store.state.heroAiCampaign.historySelected
          url = `${Constant.apiURL.salesforceCampaignHistoryDetail}/${this.$route.params.id}/${HistorySelected.campaignHistoryId}/${HistorySelected.mediaHistoryNumber}`
        }
        const response = await AxiosService.get(url)
        if (response.status) {
          this.campaign = new CampaignDetail(response.data)
          this.resetStaffPointsZero()
          this.campaign_overview = new CampaignOverviewModel(response.data.campaign_overview)
          if (this.campaign.campaign_overview) {
            this.campaign.campaign_overview.business_objectives = this.campaign_overview.business_objectives.toString()
            this.campaign.campaign_overview.campaign_status_id = this.campaign_overview.campaign_status_id.toString()
            this.$route.meta.breadcrumb[1].text = this.campaign_overview.name
            this.campaign.campaign_overview.brief_date = this.formatDatePickerDate(this.campaign.campaign_overview.brief_date)
            this.campaign.campaign_overview.actual_campaign_start_date = this.formatDatePickerDate(this.campaign.campaign_overview.actual_campaign_start_date)
            this.campaign.campaign_overview.actual_campaign_end_date = this.formatDatePickerDate(this.campaign.campaign_overview.actual_campaign_end_date)
            this.campaign.campaign_overview.paused_date = this.formatDatePickerDate(this.campaign.campaign_overview.paused_date)
            this.campaign.campaign_overview.unpaused_date = this.formatDatePickerDate(this.campaign.campaign_overview.unpaused_date)
          }

          this.$store.state.heroAiCampaign.opportunityHistory = response.data.opportunity_history
          Store.commit('heroAiCampaign/setOpportunityOverview', response.data.opportunity_overview)
          Store.commit('heroAiCampaign/setCampaign', response.data.campaign_overview)
          Store.commit('heroAiCampaign/setCreativeBriefingInfo', response.data.creative_briefing)
          Store.commit('heroAiCampaign/setWebSolutionBriefing', response.data.web_solution_briefing)
          Store.commit('heroAiCampaign/setMediaBriefing', response.data.media_briefing)
          Store.commit('heroAiCampaign/setChatbotSectionInfo', response.data.chatbot_section)
          Store.commit('heroAiCampaign/setDocumentSection', response.data.document_section)
          Store.commit('heroAiCampaign/setSEORequirementsSection', response.data.seo_requirements_section)
          Store.commit('heroAiCampaign/setStaffAssignment', response.data.staff)

          await this.getOpportunityDetail()

          this.campaign.opportunity_overview.client_requirements = this.opportunity.client_billing.client_requirements
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      } finally {
        this.dataBind = true
        this.loading = false
      }
    },
    formatDatePickerDate(dateValue) {
      if (dateValue) {
        return moment(dateValue).format(DATE_PICKER_FORMAT)
      }
      return ''
    },
    async saveCamapignData() {
      this.campaign_overview = Store.getters['heroAiCampaign/getCampaign']
      this.creative_briefing_info = Store.getters['heroAiCampaign/getCreativeBriefingInfo']
      this.web_sol_briefing = Store.getters['heroAiCampaign/getWebSolutionBriefing']
      this.media_briefing = Store.getters['heroAiCampaign/getMediaBriefing']
      this.chatbot_section_info = Store.getters['heroAiCampaign/getChatbotSectionInfo']
      this.document_section = Store.getters['heroAiCampaign/getDocumentSection']
      this.seoRequirementsSection = Store.getters['heroAiCampaign/getSEORequirementsSection']
      this.staff_assignment = Store.getters['heroAiCampaign/getStaffAssignment']

      /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
      const NewStaffAssignment = {
        account_manager: this.staff_assignment.account_manager.id || '',
        account_manager_point: this.staff_assignment.account_manager.user_level_points || '',

        optimizer_1_id: this.staff_assignment.optimizer_1.id || '',
        optimizer_1_point: this.staff_assignment.optimizer_1.user_level_points || '',
        optimizer_1_ad_platform: this.staff_assignment.optimizer_1.ad_platform || '',

        optimizer_2_id: this.staff_assignment.optimizer_2.id || '',
        optimizer_2_point: this.staff_assignment.optimizer_2.user_level_points || '',
        optimizer_2_ad_platform: this.staff_assignment.optimizer_2.ad_platform || '',

        optimizer_3_id: this.staff_assignment.optimizer_3.id || '',
        optimizer_3_point: this.staff_assignment.optimizer_3.user_level_points || '',
        optimizer_3_ad_platform: this.staff_assignment.optimizer_3.ad_platform || '',

        optimizer_4_id: this.staff_assignment.optimizer_4.id || '',
        optimizer_4_point: this.staff_assignment.optimizer_4.user_level_points || '',
        optimizer_4_ad_platform: this.staff_assignment.optimizer_4.ad_platform || '',

        optimizer_5_id: this.staff_assignment.optimizer_5.id || '',
        optimizer_5_point: this.staff_assignment.optimizer_5.user_level_points || '',
        optimizer_5_ad_platform: this.staff_assignment.optimizer_5.ad_platform || '',

        copy_writer_1_id: this.staff_assignment.copy_writer_1.id || '',
        copy_writer_1_point: this.staff_assignment.copy_writer_1.user_level_points || '',
        copy_writer_2_id: this.staff_assignment.copy_writer_2.id || '',
        copy_writer_2_point: this.staff_assignment.copy_writer_2.user_level_points || '',
        copy_writer_3_id: this.staff_assignment.copy_writer_3.id || '',
        copy_writer_3_point: this.staff_assignment.copy_writer_3.user_level_points || '',
        graphic_designer_1_id: this.staff_assignment.graphic_designer_1.id || '',
        graphic_designer_1_point: this.staff_assignment.graphic_designer_1.user_level_points || '',
        graphic_designer_2_id: this.staff_assignment.graphic_designer_2.id || '',
        graphic_designer_2_point: this.staff_assignment.graphic_designer_2.user_level_points || '',
        graphic_designer_3_id: this.staff_assignment.graphic_designer_3.id || '',
        graphic_designer_3_point: this.staff_assignment.graphic_designer_3.user_level_points || '',
        system_support: this.staff_assignment.system_support.id || '',
        system_support_point: this.staff_assignment.system_support.user_level_points || '',
        data_analyst: this.staff_assignment.data_analyst.id || '',
        data_analyst_point: this.staff_assignment.data_analyst.user_level_points || '',
        influencer_planner: this.staff_assignment.influencer_planner.id || '',
        influencer_planner_point: this.staff_assignment.influencer_planner.user_level_points || '',
        digital_associate: this.staff_assignment.digital_associate.id || '',
        digital_associate_point: this.staff_assignment.digital_associate.user_level_points || '',
        sales: this.staff_assignment.sales || '',
        viewers: this.staff_assignment.viewers || [],
        seo_members: this.staff_assignment.seo_members || [],
      }

      const payload = {
        campaign_overview: this.campaign_overview,
        creative_briefing_info: this.creative_briefing_info,
        web_sol_briefing: this.web_sol_briefing,
        media_briefing: this.media_briefing,
        chatbot_section_info: this.chatbot_section_info,
        document_section: this.document_section,
        seo_requirements_section: this.seoRequirementsSection,
        staff_assignment: NewStaffAssignment,
        campaign_id: this.$route.params.id,
      }
      try {
        const CampaignValidation = await this.validationFormCampaign()
        const BriefingValidation = await this.validationFormBriefing()
        const StaffValidation = await this.validationFormStaff()
        if (CampaignValidation === true && BriefingValidation === true && StaffValidation === true) {
          const qparam = {}
          const response = await AxiosService.patch(`${Constant.apiURL.salesforceCampaign}/${this.$route.params.id}`, qparam, payload)
          if (response.status) {
            await this.$swal({ ...SweetAlert.success, text: 'Campaign updated successfully!' })
            await this.getCampaign()
            this.isEdit = !this.isEdit
          }
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
    },
    async validationFormCampaign() {
      return this.$refs.campaignOverviewRules.validate().then(success => {
        if (success) {
          this.tabClass.campaign = 'hightlight-tab'
          return true
        }
        this.tabClass.campaign = 'hightlight-tab-error'
        return false
      })
    },
    validationFormBriefing() {
      return this.$refs.briefingRules.validate().then(success => {
        if (success) {
          this.tabClass.brief = 'hightlight-tab'
          return true
        }
        this.tabClass.brief = 'hightlight-tab-error'
        return false
      })
    },
    validationFormStaff() {
      return this.$refs.staffRules.validate().then(success => {
        if (success) {
          this.tabClass.staff = 'hightlight-tab'
          return true
        }
        this.tabClass.staff = 'hightlight-tab-error'
        return false
      })
    },
    resetStore() {
      Store.commit('heroAiCampaign/setCampaign', {})
      Store.commit('heroAiCampaign/setCreativeBriefingInfo', {})
      Store.commit('heroAiCampaign/setWebSolutionBriefing', {})
      Store.commit('heroAiCampaign/setMediaBriefing', {})
      Store.commit('heroAiCampaign/setChatbotSectionInfo', {})
      Store.commit('heroAiCampaign/setDocumentSection', {})
      Store.commit('heroAiCampaign/setSEORequirementsSection', {})
      Store.commit('heroAiCampaign/setStaffAssignment', {})
      this.$store.state.heroAiCampaign.opportunityHistory = []
      // Store.commit('heroAiCampaign/setOpportunityId', null)
    },
    async getCampaignStatusDropdownList() {
      try {
        const qparam = {
          slug: 'campaign_status',
        }
        const response = await AxiosService.get(Constant.apiURL.salesforceCampaignDropdown, qparam)
        this.campaignStatusOptions = response.data
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
    },
    async getBusinessObjectivesDropdownList() {
      try {
        const qparam = {
          slug: 'objectives',
        }
        const response = await AxiosService.get(Constant.apiURL.salesforceCampaignDropdown, qparam)
        this.businessObjectiveOption = CampaignDropdownFactory.createFromJsonArray(response.data)
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
    },
    async getLanguageDropdownList() {
      try {
        const qparam = {
          slug: 'languages',
        }
        const response = await AxiosService.get(Constant.apiURL.salesforceCampaignDropdown, qparam)
        this.languageOptions = CampaignDropdownFactory.createFromJsonArray(response.data)
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
    },
    async getLandingpgTypeDropdownList() {
      try {
        const qparam = {
          slug: 'landing_page_types',
        }
        const response = await AxiosService.get(Constant.apiURL.salesforceCampaignDropdown, qparam)
        this.landingPageTypeOptions = CampaignDropdownFactory.createFromJsonArray(response.data)
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
    },
    async getReportSystemDropdownList() {
      try {
        const qparam = {
          slug: 'report_systems',
        }
        const response = await AxiosService.get(Constant.apiURL.salesforceCampaignDropdown, qparam)
        this.reportOptions = CampaignDropdownFactory.createFromJsonArray(response.data)
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
    },

    async getUserDropdownList(slugValue) {
      try {
        const qparam = {
          slug: slugValue,
        }
        const response = await AxiosService.get(Constant.apiURL.salesforceCampaignDropdown, qparam)
        if (response.status) {
          this.amOptions = CampaignDropdownFactory.createFromJsonArray(response.data.am)
          this.optimizerOptions = CampaignDropdownFactory.createFromJsonArray(response.data.optimizer)
          this.copyWriterOptions = CampaignDropdownFactory.createFromJsonArray(response.data.copy_writer)
          this.designerOptions = CampaignDropdownFactory.createFromJsonArray(response.data.designer)
          this.systemSupportOptions = CampaignDropdownFactory.createFromJsonArray(response.data.system_support)
          this.dataAnalystOptions = CampaignDropdownFactory.createFromJsonArray(response.data.data_analyst)
          this.influencerOptions = CampaignDropdownFactory.createFromJsonArray(response.data.influencer)
          this.digitalAssociateOptions = CampaignDropdownFactory.createFromJsonArray(response.data.digital_associate)
          this.saleOptions = CampaignDropdownFactory.createFromJsonArray(response.data.sale)
          this.viewersOptions = CampaignDropdownFactory.createFromJsonArray(response.data.viewers)
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
    },
    async getAdPlatformDropdownList() {
      try {
        const response = await AxiosService.get(`${Constant.apiURL.salesforceDropdownOptions}/ad-platform-options`)
        if (response) {
          this.adPlatformOptions = response.data.map(x => ({
            text: x,
            value: x,
          }))
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
    },
    async getUserPointDropdownList() {
      try {
        const qparam = {
          slug: 'user_level_points',
        }
        const response = await AxiosService.get(Constant.apiURL.salesforceCampaignDropdown, qparam)
        this.pointOptions = CampaignDropdownFactory.createFromJsonArray(response.data)
        this.zeroPointId = this.pointOptions.filter(x => x.text === '0').map(x => x.value)[0].toString()
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
    },
    resetStaffPointsZero() {
      if (this.campaign && this.campaign.staff) {
        if (this.campaign.staff.account_manager.user_level_points === '') {
          this.campaign.staff.account_manager.user_level_points = this.zeroPointId
        }
        if (this.campaign.staff.optimizer_1.user_level_points === '') {
          this.campaign.staff.optimizer_1.user_level_points = this.zeroPointId
        }
        if (this.campaign.staff.optimizer_2.user_level_points === '') {
          this.campaign.staff.optimizer_2.user_level_points = this.zeroPointId
        }
        if (this.campaign.staff.optimizer_3.user_level_points === '') {
          this.campaign.staff.optimizer_3.user_level_points = this.zeroPointId
        }
        if (this.campaign.staff.optimizer_4.user_level_points === '') {
          this.campaign.staff.optimizer_4.user_level_points = this.zeroPointId
        }
        if (this.campaign.staff.optimizer_5.user_level_points === '') {
          this.campaign.staff.optimizer_5.user_level_points = this.zeroPointId
        }
        if (this.campaign.staff.copy_writer_1.user_level_points === '') {
          this.campaign.staff.copy_writer_1.user_level_points = this.zeroPointId
        }
        if (this.campaign.staff.copy_writer_2.user_level_points === '') {
          this.campaign.staff.copy_writer_2.user_level_points = this.zeroPointId
        }
        if (this.campaign.staff.copy_writer_3.user_level_points === '') {
          this.campaign.staff.copy_writer_3.user_level_points = this.zeroPointId
        }
        if (this.campaign.staff.graphic_designer_1.user_level_points === '') {
          this.campaign.staff.graphic_designer_1.user_level_points = this.zeroPointId
        }
        if (this.campaign.staff.graphic_designer_2.user_level_points === '') {
          this.campaign.staff.graphic_designer_2.user_level_points = this.zeroPointId
        }
        if (this.campaign.staff.graphic_designer_3.user_level_points === '') {
          this.campaign.staff.graphic_designer_3.user_level_points = this.zeroPointId
        }
        if (this.campaign.staff.system_support.user_level_points === '') {
          this.campaign.staff.system_support.user_level_points = this.zeroPointId
        }
        if (this.campaign.staff.data_analyst.user_level_points === '') {
          this.campaign.staff.data_analyst.user_level_points = this.zeroPointId
        }
        if (this.campaign.staff.influencer_planner.user_level_points === '') {
          this.campaign.staff.influencer_planner.user_level_points = this.zeroPointId
        }
        if (this.campaign.staff.digital_associate.user_level_points === '') {
          this.campaign.staff.digital_associate.user_level_points = this.zeroPointId
        }
      }
    },
  },
}
</script>
<style>
  .ml-auto {
    margin-left:auto;
  }
  .d-block {
    display:block;
  }
  .hightlight-tab > a{
    overflow: hidden;
    background-color: #ddd;
    border-radius: 9px;
    margin: 5px;
  }
  .hightlight-tab:hover > a{
    background-color: #ddd;
  }
  .hightlight-tab-error > a{
    overflow: hidden;
    background-color: #ea5455 !important;
    border-radius: 9px;
    margin: 5px;
    color: #ddd !important;
  }
</style>
