<template>
  <div>
    <div v-if="products !== null" class="mt-2">
      <b-table-simple hover striped responsive bordered>
        <b-thead head-variant="dark">
          <b-tr>
            <b-th v-for="(column, index) in columns" :key="index" :style="'vertical-align: middle; font-size: 11px; text-align: center; min-width: ' + column.width">
              {{ column.label }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-for="(product, index) in products" :key="index">
          <b-tr>
            <b-th style="text-align: left; background-color:grey; color: white;" colspan="13">
              {{ product.product }}
            </b-th>
          </b-tr>
          <template v-for="(childData, childIndex) in product.children">
            <b-tr v-if="childData.is_total === false && childData.is_deleted === false" :key="childIndex">
              <b-td class="text-left">
                <img
                  :src="getImage(childData.product_family_name)"
                  alt=""
                  width="15"
                  height="15"
                >
                {{ childData.name }}
              </b-td>
              <b-td class="p-1">
                <validation-provider
                  #default="{ errors }"
                  name="net budget"
                  rules="required"
                  :vid="`vid-net-budget-${index}-${childIndex}-${cycleIndex}`"
                >
                  <HeroInputNumber
                    :id="`net_budget-${index}-${childIndex}-${cycleIndex}`"
                    v-model="childData.net_budget"
                    :clearable="false"
                    :required="true"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                    @input="calculateGrandTotal()"
                  />
                </validation-provider>
              </b-td>
              <b-td class="p-1">
                <validation-provider
                  #default="{ errors }"
                  name="ads budget"
                  :vid="`vid-ads-budget-${index}-${childIndex}-${cycleIndex}`"
                >
                  <HeroInputNumber
                    :id="`ads_budget-${index}-${childIndex}-${cycleIndex}`"
                    v-model="childData.ads_budget"
                    :clearable="false"
                    :required="true"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                    :readonly="true"
                  />
                </validation-provider>
              </b-td>
              <b-td class="p-1">
                <validation-provider
                  #default="{ errors }"
                  name="management fee"
                  :rules="setManagementFee(childData.id)"
                  :vid="`vid-management-fee-${index}-${childIndex}-${cycleIndex}`"
                >
                  <HeroInputNumber
                    :id="`management_fee-${index}-${childIndex}-${cycleIndex}`"
                    v-model="childData.management_fee"
                    :clearable="false"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                    @input="calculateGrandTotal()"
                  />
                </validation-provider>
              </b-td>

              <b-td class="p-1">
                <validation-provider
                  #default="{ errors }"
                  name="kpi per cycle"
                  rules="required"
                  :vid="`vid-kpi-per-cycle-${index}-${childIndex}-${cycleIndex}`"
                >
                  <HeroInputNumber
                    :id="`kpi-cycle-${index}-${childIndex}-${cycleIndex}`"
                    v-model="childData.kpi"
                    :clearable="false"
                    :required="true"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                    @input="calculateGrandTotal()"
                  />
                </validation-provider>
              </b-td>
              <b-td class="p-1">
                <validation-provider
                  #default="{ errors }"
                  name="kpi unit"
                  rules="required"
                  :vid="`vid-kpi-unit-${index}-${childIndex}-${cycleIndex}`"
                >
                  <HeroVueSelect
                    :id="`kpi-unit-${index}-${childIndex}-${cycleIndex}`"
                    v-model="childData.kpi_unit_id"
                    :options="kpiOptions"
                    :clearable="false"
                    :required="true"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                    @input="calculateGrandTotal()"
                  />
                </validation-provider>
              </b-td>
              <b-td class="p-1">
                <validation-provider
                  #default="{ errors }"
                  name="cost per unit"
                  rules="required"
                  :vid="`vid-cost-per-unit-${index}-${childIndex}-${cycleIndex}`"
                >
                  <HeroInputNumber
                    :id="`cost_per_unit-${index}-${childIndex}-${cycleIndex}`"
                    v-model="childData.cost_per_unit"
                    :clearable="false"
                    :required="true"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                    :readonly="true"
                  />
                </validation-provider>
              </b-td>
              <b-td class="p-1">
                <HeroTextarea
                  :id="`comment-${index}-${childIndex}-${cycleIndex}`"
                  v-model="childData.comment"
                  :clearable="false"
                />
              </b-td>
              <b-td class="p-1">
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules="required"
                  :vid="`vid-product-status-name-${index}-${childIndex}-${cycleIndex}`"
                >
                  <HeroVueSelect
                    :id="`product-status-name-${index}-${childIndex}-${cycleIndex}`"
                    v-model="childData.product_status_name"
                    :options="productStatusList"
                    :clearable="false"
                    :required="true"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                  />
                </validation-provider>
              </b-td>
              <b-td class="p-1">
                <HeroInputDate
                  :id="`start-date-${index}-${childIndex}-${cycleIndex}`"
                  v-model="childData.product_start_date"
                  :required="true"
                />
              </b-td>
              <b-td class="p-1">
                <HeroInputDate
                  :id="`end-date-${index}-${childIndex}-${cycleIndex}`"
                  v-model="childData.product_end_date"
                  :required="true"
                />
              </b-td>

              <!-- !! Wallet Budget Name / HeroAI Campaign Id -->
              <b-td v-if="!childData.is_total" role="cell">
                <div>
                  {{ childData.wallet_budget_name }}
                </div>
              </b-td>

              <b-td @click="removeRow(index, childIndex)">
                <div class="d-flex">
                  <feather-icon
                    icon="TrashIcon"
                  />
                </div>
              </b-td>
            </b-tr>
          </template>
          <b-tr v-if="product.product == 'Media Products'">
            <b-td width="25%" variant="primary">
              Media Total
            </b-td>
            <b-td width="10%" class="text-right" variant="primary">
              {{ formatMoney(getGrandTotalForMedia.mediaNetBudget) }}
            </b-td>
            <b-td width="10%" class="text-right" variant="primary">
              {{ formatMoney(getGrandTotalForMedia.mediaAdsBudget) }}
            </b-td>
            <b-td
              class="text-center"
              colspan="10"
              variant="primary"
            >
              {{ getGrandTotalForMedia.kpiSummaryText }}
            </b-td>
          </b-tr>
          <b-tr v-if="product.product == 'Media Products'">
            <b-td style="text-align: left;" colspan="13">
              <HeroInputText
                :id="`media-plan-${index}`"
                v-model="getMediaPlanLink"
                label="Media Plan"
                :clearable="false"
                :required="true"
                :readonly="true"
              />
            </b-td>
          </b-tr>
          <b-tr>
            <b-td colspan="13">
              <hr>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td variant="primary">
              Total
            </b-td>
            <b-td variant="primary" class="text-right">
              {{ formatMoney(getGrandTotal.net_budget) }}
            </b-td>
            <b-td variant="primary" class="text-right">
              {{ formatMoney(getGrandTotal.ads_budget) }}
            </b-td>
            <b-td class="text-center" colspan="10" variant="primary">
              {{ getGrandTotal.kpi_summary }}
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </div>
    <AddProduct
      :campaign-product-options="campaignProductOptions"
      :products="allproducts"
      :product-options="productOptions"
      :cycle-campaign-product-ids="cycleCampaignProductIds"
      :cycle-index="cycleIndex"
      @addProductData="getAddProductData($event)"
    />
  </div>
</template>
<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import HeroInputDate from '@/views/components/form/inputs/HeroInputDate.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import DataFormatService from '@/services/DataFormatService'
import AddProduct from '@/views/heroai/campaigns/components/view/Campaign/MediaPlan/Cycle/Edit/AddProduct.vue'
import Constant from '@/utils/Constant'

export default {
  components: {
    HeroInputText,
    HeroInputDate,
    HeroTextarea,
    HeroInputNumber,
    HeroVueSelect,
    AddProduct,
  },
  props: {
    campaignProductOptions: {
      type: Array,
      default() {
        return []
      },
    },
    cycleNumber: {
      type: Number,
      default: null,
    },
    products: {
      type: Array,
      default: null,
    },
    cycleIndex: {
      type: Number,
      required: true,
    },
    clientPayForMedia: {
      type: Boolean,
      default: false,
    },
    kpiOptions: {
      type: Array,
      default() {
        return []
      },
    },
    allproducts: {
      type: Array,
      default() {
        return []
      },
    },
    productOptions: {
      type: Array,
      default() {
        return []
      },
    },
    cycle: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'Product',
          field: 'product',
          width: '200px',
        },
        {
          label: 'Net Budget (THB)',
          field: 'net_budget',
          width: '140px',
        },
        {
          label: 'Ads.Budget (THB)',
          field: 'ads_budget',
          width: '140px',
        },
        {
          label: 'Management Fee(+Buffer)',
          field: 'management_fee',
          width: '100px',
        },

        {
          label: 'KPI Per Cycle',
          field: 'kpi',
          width: '130px',
        },
        {
          label: 'KPI Unit',
          field: 'kpi_unit_id',
          width: '230px',
        },
        {
          label: 'CP KPI Unit (THB)',
          field: 'cost_per_unit',
          width: '130px',
        },
        {
          label: 'Comment',
          field: 'comment',
          width: '200px',
        },
        {
          label: 'Status',
          field: 'product_status_name',
          width: '180px',
        },
        {
          label: 'Start Date',
          field: 'product_start_date',
          width: '200px',
        },
        {
          label: 'End Date',
          field: 'product_end_date',
          width: '200px',
        },
        {
          label: 'HeroAI Campaign ID',
          field: 'wallet_budget_name',
          width: '200px',
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
        },
      ],
      isPageLoad: false,
      getGrandTotal: {
        net_budget: 0,
        ads_budget: 0,
        kpi_summary: 0,
      },
      productStatusList: Constant.productStatusList,
      cycleCampaignProductIds: [],
    }
  },
  computed: {
    getMediaPlanLink() {
      return this.$store.state.heroAiCampaign.media_briefing.media_plan_link || ''
    },
    getGrandTotalForMedia() {
      const ProductList = []
      const GrandTotalArray = []
      let mediaNetBudget = 0
      let mediaAdsBudget = 0
      this.products.forEach(products => {
        const childrens = products.children.filter(x => x.is_total === false && x.is_media === true && x.is_deleted === false)
        childrens.forEach(childData => {
          const KPIUnitIndex = GrandTotalArray.findIndex(x => x.id === childData.kpi_unit_id)
          const KPIPerCycle = parseInt(childData.kpi, 10)
          if (KPIUnitIndex > -1) {
            GrandTotalArray[KPIUnitIndex].value += KPIPerCycle
          } else {
            GrandTotalArray.push({
              id: childData.kpi_unit_id,
              value: KPIPerCycle,
            })
          }
          ProductList.push(childData)
          mediaNetBudget += parseFloat(childData.net_budget)
          mediaAdsBudget += parseFloat(childData.ads_budget)
        })
      })
      const KPISummary = []
      let kpiSummaryText = ''
      if (GrandTotalArray && GrandTotalArray.length > 0) {
        GrandTotalArray.forEach(kpidata => {
          const kpiUnitName = this.kpiOptions.filter(x => x.value === kpidata.id)
          if (kpiUnitName && kpiUnitName.length > 0) {
            KPISummary.push(`${kpidata.value} ${kpiUnitName[0].text}`)
          }
        })
        kpiSummaryText = KPISummary.join(' || ')
      }
      return { kpiSummaryText, mediaNetBudget, mediaAdsBudget }
    },
  },
  watch: {
    products: {
      deep: true,
      async handler() {
        this.getCycleCampaignProductIds()
      },
    },

    'cycle.start_date': {
      deep: true,
      handler(newDate, oldDate) {
        if (newDate !== oldDate) {
          this.changeProductStartDate(newDate)
        }
      },
    },

    'cycle.end_date': {
      deep: true,
      handler(newDate, oldDate) {
        if (newDate !== oldDate) {
          this.changeProductEndDate(newDate)
        }
      },
    },
  },

  async created() {
    this.formatMoney = DataFormatService.formatMoney

    await this.$store.commit('SourceWalletBudgetOptions/setSourceId', this.$route.params.id)
    await this.$store.commit('SourceWalletBudgetOptions/setSourceType', 'Campaign')
    await this.$store.commit('SourceWalletBudgetOptions/setDoUpdate', true)
    await this.$store.dispatch('SourceWalletBudgetOptions/fetchOptions')
  },
  async mounted() {
    await this.calculateGrandTotal()
    this.isPageLoad = true
  },
  methods: {
    getImage(type) {
      const images = require.context('@/assets/images/social-icons', false, /\.png$/)
      let imageURL = images('./Hero.png')
      if (type === 'Hero Google' || type === 'AdWords') {
        imageURL = images('./google.png')
      } else if (type === 'Facebook' || type === 'Hero Facebook') {
        imageURL = images('./facebook.png')
      } else if (type === 'Line' || type === 'Line Ads' || type === 'Hero Line') {
        imageURL = images('./line.png')
      } else if (type === 'Hero Call Tracking' || type === 'Call Tracking') {
        imageURL = images('./mobile-phone.png')
      } else if (type === 'Direct') {
        imageURL = images('./landing-page.png')
      } else if (type === 'Instagram' || type === 'Hero Instagram') {
        imageURL = images('./instagram.png')
      } else if (type === 'YouTube' || type === 'Hero Youtube') {
        imageURL = images('./youtube.png')
      } else if (type === 'Messenger' || type === 'Hero Chatbot') {
        imageURL = images('./messenger.png')
      } else if (type === 'Email' || type === 'Hero Mail') {
        imageURL = images('./gmail.png')
      } else if (type === 'Linkedin') {
        imageURL = images('./linkedin.png')
      } else if (type === 'SMS') {
        imageURL = images('./sms.png')
      } else if (type === 'Taboola') {
        imageURL = images('./taboola.png')
      } else if (type === 'Twitter') {
        imageURL = images('./twitter.png')
      } else if (type === 'Whatsapp') {
        imageURL = images('./whatsapp.png')
      }
      return imageURL
    },
    async removeRow(index, childIndex) {
      this.products[index].children[childIndex].is_deleted = true
      let netBudget = parseFloat(this.products[index].children[childIndex].net_budget)
      this.products[index].children[childIndex].net_budget = netBudget + 1

      setTimeout(() => {
        netBudget = parseFloat(this.products[index].children[childIndex].net_budget)
        this.products[index].children[childIndex].net_budget = netBudget - 1
        this.calculateGrandTotal()
      }, 1000)
    },
    getAddProductData(productObj) {
      const addProductObj = productObj
      addProductObj.product_start_date  = this.cycle.start_date
      addProductObj.product_end_date    = this.cycle.end_date
      addProductObj.product_status_id   = this.cycle.status_id
      addProductObj.product_status_name = this.cycle.status_name
      addProductObj.kpi_unit_id         = '610210c41089bd35a93cc034' // No Kpi

      if (!addProductObj.is_media) {
        const nonMediaIndex = this.products.findIndex(x => x.product.toLowerCase() === 'non media products')
        if (nonMediaIndex > -1) {
          this.products[nonMediaIndex].children.push(addProductObj)
        } else {
          const nonMediaProductObj = {
            product: 'Non Media Products',
            children: [addProductObj],
          }
          this.products.push(nonMediaProductObj)
        }
      } else {
        const mediaIndex = this.products.findIndex(x => x.product.toLowerCase() === 'media products')
        if (mediaIndex > -1) {
          this.products[mediaIndex].children.push(addProductObj)
        } else {
          const mediaProductObj = {
            product: 'Media Products',
            children: [addProductObj],
          }
          this.products.push(mediaProductObj)
        }
      }
      this.calculateGrandTotal()
    },
    setManagementFee(productId) {
      const Product = this.allproducts.filter(x => x.id === productId)
      let managementFee = 0
      if (Product && Product.length > 0) {
        managementFee = Product[0].management_fee
      }
      managementFee = 0
      return `required|between:${managementFee},100`
    },
    async calculateGrandTotal() {
      const ProductList = []
      const GrandTotalArray = []
      this.products.forEach((products, index) => {
        products.children.forEach((childData, childIndex) => {
          if (childData.is_total === false && childData.is_deleted === false) {
            const KPICycle = childData.kpi
            if (childData.net_budget) {
              this.products[index].children[childIndex].ads_budget = parseFloat(childData.net_budget) - ((parseFloat(childData.net_budget) * (parseFloat(childData.management_fee))) / 100).toFixed(2)
              this.products[index].children[childIndex].cost_per_unit = KPICycle && KPICycle > 0 ? (parseFloat(childData.net_budget) / parseFloat(childData.kpi)).toFixed(2) : parseFloat(childData.net_budget).toFixed(2)
            } else {
              this.products[index].children[childIndex].ads_budget = parseFloat(0).toFixed(2)
              this.products[index].children[childIndex].cost_per_unit = parseFloat(0).toFixed(2)
            }
            const KPIUnitIndex = GrandTotalArray.findIndex(x => x.id === childData.kpi_unit_id)
            const KPIPerCycle = parseInt(childData.kpi, 10)
            if (KPIUnitIndex > -1) {
              GrandTotalArray[KPIUnitIndex].value += KPIPerCycle
            } else {
              GrandTotalArray.push({
                id: childData.kpi_unit_id,
                value: KPIPerCycle,
              })
            }
            ProductList.push(childData)
          }
        })
      })
      const KPISummary = []
      let kpiSummaryText = ''
      if (GrandTotalArray && GrandTotalArray.length > 0) {
        GrandTotalArray.forEach(kpidata => {
          const kpiUnitName = this.kpiOptions.filter(x => x.value === kpidata.id)
          if (kpiUnitName && kpiUnitName.length > 0) {
            KPISummary.push(`${kpidata.value} ${kpiUnitName[0].text}`)
          }
        })
        kpiSummaryText = KPISummary.join(' || ')
      }
      const TotalNetBudget = ProductList.map(x => parseFloat(x.net_budget))
      const TotalNet = TotalNetBudget.reduce((partialSum, a) => partialSum + a, 0)
      let TotalNetBudgetRemaining = TotalNetBudget
      if (this.clientPayForMedia) {
        TotalNetBudgetRemaining = ProductList.filter(x => x.is_media === false).map(x => parseFloat(x.net_budget))
      }
      const TotalNetRemaining = TotalNetBudgetRemaining.reduce((partialSum, a) => partialSum + a, 0)
      const TotalAdsBudget = ProductList.map(x => parseFloat(x.ads_budget))
      const TotalAds = TotalAdsBudget.reduce((partialSum, a) => partialSum + a, 0)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.$store.state.heroAiCampaign.cycleTotals.splice(this.cycleIndex, 1, TotalNet)
      this.$store.state.heroAiCampaign.cycleTotalsRemaining.splice(this.cycleIndex, 1, TotalNetRemaining)
      if (this.isPageLoad) {
        if (this.clientPayForMedia) {
          const prevTotalRemaining = this.$store.state.heroAiCampaign.cycleTotalsRemaining[this.cycleIndex]
          // console.log(totalHistory, 'totalHistory')
          // console.log(TotalNetRemaining, 'TotalNetRemaining')
          // console.log(this.$store.state.heroAiCampaign.cycleTotalsRemaining[this.cycleIndex], 'this.$store.state.heroAiCampaign.cycleTotalsRemaining[this.cycleIndex]')
          let remainingDiff = 0
          if (prevTotalRemaining > TotalNetRemaining) {
            remainingDiff = prevTotalRemaining - TotalNetRemaining
          } else {
            remainingDiff = TotalNetRemaining - prevTotalRemaining
          }
          // console.log(remainingDiff, 'remainingDiff')
          let differnceAmount = 0
          if (remainingDiff > TotalNetRemaining) {
            differnceAmount = this.$store.state.heroAiCampaign.cycleTotalsRemaining[this.cycleIndex] + remainingDiff
            // console.log(differnceAmount, 'differnceAmount')
          } else {
            differnceAmount = this.$store.state.heroAiCampaign.cycleTotalsRemaining[this.cycleIndex] - remainingDiff
            // console.log(differnceAmount, 'differnceAmount1')
          }
          // console.log(differnceAmount, 'differnceAmount2')
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.$store.state.heroAiCampaign.cycleTotalsRemaining.splice(this.cycleIndex, 1, differnceAmount)
        } else {
          // console.log(TotalNet, 'TotalNet')
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.$store.state.heroAiCampaign.cycleTotalsRemaining.splice(this.cycleIndex, 1, TotalNet)
        }
      }
      this.getGrandTotal = { net_budget: TotalNet, ads_budget: TotalAds, kpi_summary: kpiSummaryText }
    },

    getCycleCampaignProductIds() {
      const mediaProducts    = this.products[0].children
      const nonMediaProducts = this.products[1].children

      this.cycleCampaignProductIds = []

      if (mediaProducts.length > 0) {
        mediaProducts.forEach(mediaPlan => {
          if (mediaPlan.campaign_product_id) {
            if (!mediaPlan.is_deleted) {
              this.cycleCampaignProductIds.push(mediaPlan.campaign_product_id)
            }
          }
        })
      }

      if (nonMediaProducts.length > 0) {
        nonMediaProducts.forEach(mediaPlan => {
          if (mediaPlan.campaign_product_id) {
            if (!mediaPlan.is_deleted) {
              this.cycleCampaignProductIds.push(mediaPlan.campaign_product_id)
            }
          }
        })
      }
    },

    changeProductStartDate(cycleStartDate) {
      const mediaProducts    = this.products[0].children
      const nonMediaProducts = this.products[1].children

      mediaProducts.forEach((mediaPlan, mediaPlanIndex) => {
        this.products[0].children[mediaPlanIndex].product_start_date = cycleStartDate
      })

      nonMediaProducts.forEach((mediaPlan, mediaPlanIndex) => {
        this.products[1].children[mediaPlanIndex].product_start_date = cycleStartDate
      })
    },

    changeProductEndDate(cycleEndDate) {
      const mediaProducts    = this.products[0].children
      const nonMediaProducts = this.products[1].children

      mediaProducts.forEach((mediaPlan, mediaPlanIndex) => {
        this.products[0].children[mediaPlanIndex].product_end_date = cycleEndDate
      })

      nonMediaProducts.forEach((mediaPlan, mediaPlanIndex) => {
        this.products[1].children[mediaPlanIndex].product_end_date = cycleEndDate
      })
    },
  },
}
</script>
