<template>
  <b-tab title="Brief" :title-item-class="tabClass" class="mx-1">
    <template v-if="creative">
      <CreativeBriefing v-if="isEdit" :creative-briefing-info="creative" />
      <CreativeBriefingDisplay v-else :creative="creative" />
    </template>
    <template v-if="webSolution">
      <WebSolutionBriefing v-if="isEdit" :landing-page-type-options="landingPageTypeOptions" :language-options="languageOptions" :web-sol-briefing="webSolution" :is-edit="isEdit" />
      <WebSolutionBriefingDisplay v-else :web_solution="webSolution" />
    </template>
    <template v-if="media">
      <MediaBriefing v-if="isEdit" :ad-language-options="languageOptions" :report-options="reportOptions" :media-briefing="media" />
      <MediaBriefingDisplay v-else :media="media" />
    </template>
    <template v-if="chatbot">
      <ChatbotSection v-if="isEdit" :chatbot-section-info="chatbot" />
      <ChatbotSectionDisplay v-else :chatbot="chatbot" />
    </template>
    <template v-if="document">
      <document-section v-if="document && isEdit" :document-section="document" />
      <document-section-display v-else-if="document" :document="document" />
    </template>
    <template v-if="systemDetail">
      <SystemDetail :is-edit="isEdit" :system-detail="systemDetail" />
    </template>
    <template>
      <SEORequirementSection
        v-if="isEdit"
        :seo-requirements="seoRequirements"
        :seo-members-options="seoMembersOptions"
      />
      <SEORequirementSectionDisplay v-else :seo-requirements="seoRequirements" />
    </template>
  </b-tab>
</template>
<script>
import SEORequirementSectionDisplay
from '@/views/heroai/campaigns/components/view/Campaign/campaign-overview/SEORequirementSection.vue'
import SEORequirementSection
from '@/views/heroai/campaigns/components/create/SEORequirementSection.vue'
import CreativeBriefingDisplay from './CreativeBriefing.vue'
import CreativeBriefing from '../../../create/CreativeBriefing.vue'
import WebSolutionBriefingDisplay from './WebSolutionBriefing.vue'
import WebSolutionBriefing from '../../../create/WebSolutionBriefing.vue'
import MediaBriefingDisplay from './MediaBriefing.vue'
import MediaBriefing from '../../../create/MediaBriefing.vue'
import ChatbotSection from '../../../create/ChatbotSection.vue'
import ChatbotSectionDisplay from './ChatbotSection.vue'
import DocumentSectionDisplay from './DocumentSection.vue'
import DocumentSection from '../../../create/DocumentSection.vue'
import SystemDetail from './SystemDetail.vue'

export default {
  components: {
    SEORequirementSection,
    SEORequirementSectionDisplay,
    CreativeBriefing,
    CreativeBriefingDisplay,
    WebSolutionBriefing,
    WebSolutionBriefingDisplay,
    MediaBriefing,
    MediaBriefingDisplay,
    ChatbotSection,
    ChatbotSectionDisplay,
    DocumentSection,
    DocumentSectionDisplay,
    SystemDetail,
  },
  props: {
    isEdit: Boolean,
    creative: {
      type: Object,
      require: true,
      default: () => {},
    },
    webSolution: {
      type: Object,
      require: true,
      default: () => {},
    },
    media: {
      type: Object,
      require: true,
      default: () => {},
    },
    chatbot: {
      type: Object,
      require: true,
      default: () => {},
    },
    document: {
      type: Object,
      require: true,
      default: () => {},
    },
    seoRequirements: {
      type: Object,
      require: true,
      default: () => {},
    },
    systemDetail: {
      type: Object,
      require: true,
      default: () => {},
    },
    tabClass: {
      type: String,
      required: false,
      default: '',
    },
    landingPageTypeOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    languageOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    reportOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    seoMembersOptions: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    linkClass() {
      if (this.CampaignValidation === true && this.BriefingValidation === true && this.$optionsStaffValidation === true) {
        return ['hightlight-tab']
      }
      return ['hightlight-tab-error']
    },
  },
}
</script>
