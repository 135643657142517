var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"addNewProductRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('validation-provider',{attrs:{"name":"product","rules":_vm.$store.state.heroAiCampaign.isAddProduct ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":("add-new-product-" + _vm.cycleIndex),"label":"Add New Product","options":_vm.usableCampaignProductOptions,"required":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.selectedCampaignProductId),callback:function ($$v) {_vm.selectedCampaignProductId=$$v},expression:"selectedCampaignProductId"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"net budget","rules":_vm.$store.state.heroAiCampaign.isAddProduct ? 'required|max:100' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputNumber',{attrs:{"id":("net-budget-" + _vm.cycleIndex),"label":"Net Budget","required":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.productModel.net_budget),callback:function ($$v) {_vm.$set(_vm.productModel, "net_budget", $$v)},expression:"productModel.net_budget"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"management fee","rules":_vm.$store.state.heroAiCampaign.isAddProduct ? 'required|max:100' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputNumber',{attrs:{"id":("management-fee-" + _vm.cycleIndex),"label":"Management Fee(%)","required":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.productModel.management_fee),callback:function ($$v) {_vm.$set(_vm.productModel, "management_fee", $$v)},expression:"productModel.management_fee"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"budget","rules":_vm.$store.state.heroAiCampaign.isAddProduct ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":("wallet_budget_id-" + _vm.cycleIndex),"label":"Budget","clearable":false,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"options":_vm.$store.getters['SourceWalletBudgetOptions/getOptions']},model:{value:(_vm.selectWalletBudgetId),callback:function ($$v) {_vm.selectWalletBudgetId=$$v},expression:"selectWalletBudgetId"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"1"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"btnmrgn",attrs:{"variant":"primary"},on:{"click":_vm.addProductData}},[_vm._v(" +Add ")])],1),_c('b-col',{attrs:{"cols":"12","md":"10"}},[_c('HeroTextarea',{attrs:{"id":("comment-" + _vm.cycleIndex),"label":"Comment"},model:{value:(_vm.productModel.comment),callback:function ($$v) {_vm.$set(_vm.productModel, "comment", $$v)},expression:"productModel.comment"}})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('HeroDisplayText',{attrs:{"id":("pricing-model-text-" + _vm.cycleIndex),"custom-style":"margin-top:12px","label":"Pricing Model"},model:{value:(_vm.pricing_model_text),callback:function ($$v) {_vm.pricing_model_text=$$v},expression:"pricing_model_text"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }