<template>
  <b-tab title="Payment Calendar">
    <b-table-simple responsive bordered class="text-center" style="font-weight: normal; font-size: 12px; white-space:nowrap;">
      <b-thead>
        <b-tr>
          <b-th style="vertical-align: middle;" rowspan="2">
            Opportunity Name
          </b-th>
          <b-th style="vertical-align: middle;" rowspan="2">
            Type
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item, index) in calendarList" :key="index">
          <b-td>
            <HeroButtonAction
              type="button"
              variant="primary"
              btnclass="new-campaign"
              @click="gotoOpportunity(item.opportunity_id)"
            >
              {{ item.opportunity.name }}
            </HeroButtonAction>
          </b-td>
          <b-td>
            <b-badge
              :variant="getBadgeVariant(item.action)"
              class="pl-2 pr-2 pt-1 pb-1"
            >
              <span>{{ item.action }}</span>
            </b-badge>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-tab>
</template>
<script>
import AxiosService from '@/services/AxiosService'
import Constant from '@/utils/Constant'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'

export default {
  components: {
    HeroButtonAction,
  },
  data() {
    return {
      calendarList: [],
    }
  },
  mounted() {
    this.doLoadData()
  },
  methods: {
    async doLoadData() {
      try {
        const response = await AxiosService.get(Constant.apiURL.campaignPaymentCalenders.replace('{0}', this.$route.params.id))

        if (response.status === true) {
          this.calendarList = response.data
        } else {
          this.calendarList = []
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
        this.calendarDetail = []
      }
    },
    getBadgeVariant(action) {
      if (action.toLowerCase() === 'create') {
        return 'primary'
      } if (action.toLowerCase() === 'upsell') {
        return 'success'
      } if (action.toLowerCase() === 'retain') {
        return 'warning'
      }
      return ''
    },
    gotoOpportunity(opportunityid) {
      const routeData = this.$router.resolve({ name: 'opportunities-detail', params: { id: opportunityid } })
      window.open(routeData.href, '_blank')
    },
  },
}
</script>

<style scoped>
.badge-primary {
  background-color: #FF5722
}
.badge-success {
  background-color: #28B446
}
.badge-warning {
  background-color: #FBBB00
}
</style>
