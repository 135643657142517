<template>
  <b-row class="mx-1">
    <b-col cols="12">
      <b-row>
        <b-col cols="12" md="4">
          <HeroDisplayText
            id="am"
            :value="staff.account_manager.name"
            label="AM"
          />
        </b-col>
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="am-point"
            :value="staff.account_manager.user_level_points_display"
            label="AM Point"
          />
        </b-col>

        <b-col cols="12" md="4">
          <HeroDisplayText
            id="designer 1"
            :value="staff.graphic_designer_1.name"
            label="Designer 1"
          />
        </b-col>
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="gd-1-point"
            :value="staff.graphic_designer_1.user_level_points_display"
            label="GD 1 Point"
          />
        </b-col>

        <b-col cols="12" md="4">
          <HeroDisplayText
            id="system-support"
            :value="staff.system_support.name"
            label="System Support"
          />
        </b-col>
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="ss-point"
            :value="staff.system_support.user_level_points_display"
            label="SS Point"
          />
        </b-col>

        <b-col cols="12" md="4">
          <HeroDisplayText
            id="designer 2"
            :value="staff.graphic_designer_2.name"
            label="Designer 2"
          />
        </b-col>
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="gd-2-point"
            :value="staff.graphic_designer_2.user_level_points_display"
            label="GD 2 Point"
          />
        </b-col>

        <b-col cols="12" md="4">
          <HeroDisplayText
            id="data-analyst"
            :value="staff.data_analyst.name"
            label="Data Analyst"
          />
        </b-col>
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="da-point"
            :value="staff.data_analyst.user_level_points_display"
            label="DA Point"
          />
        </b-col>

        <b-col cols="12" md="4">
          <HeroDisplayText
            id="designer 3"
            :value="staff.graphic_designer_3.name"
            label="Designer 3"
          />
        </b-col>
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="gd-3-point"
            :value="staff.graphic_designer_3.user_level_points_display"
            label="GD 3 Point"
          />
        </b-col>

        <b-col cols="12" md="4">
          <HeroDisplayText
            id="influencer"
            :value="staff.influencer_planner.name"
            label="Influencer"
          />
        </b-col>
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="if-point"
            :value="staff.influencer_planner.user_level_points_display"
            label="IF Point"
          />
        </b-col>

        <b-col cols="12" md="4">
          <HeroDisplayText
            id="copy-writer-1"
            :value="staff.copy_writer_1.name"
            label="Copy Writer 1"
          />
        </b-col>
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="copy-writer-1-point"
            :value="staff.copy_writer_1.user_level_points_display"
            label="Copy Writer 1 Point"
          />
        </b-col>

        <b-col cols="12" md="4">
          <HeroDisplayText
            id="digital-associate"
            :value="staff.digital_associate.name"
            label="Digital Associate"
          />
        </b-col>
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="dma-point"
            :value="staff.digital_associate.user_level_points_display"
            label="DMA Point"
          />
        </b-col>

        <b-col cols="12" md="4">
          <HeroDisplayText
            id="copy-writer-2"
            :value="staff.copy_writer_2.name"
            label="Copy Writer 2"
          />
        </b-col>
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="copy-writer-2-point"
            :value="staff.copy_writer_2.user_level_points_display"
            label="Copy Writer 2 Point"
          />
        </b-col>

        <b-col cols="12" md="6">
          <HeroDisplayText
            id="sale"
            :value="staff.sales_name"
            label="Sale"
          />
        </b-col>

        <b-col cols="12" md="4">
          <HeroDisplayText
            id="copy-writer-3"
            :value="staff.copy_writer_3.name"
            label="Copy Writer 3"
          />
        </b-col>
        <b-col cols="12" md="2">
          <HeroDisplayText
            id="copy-writer-3-point"
            :value="staff.copy_writer_3.user_level_points_display"
            label="Copy Writer 3 Point"
          />
        </b-col>

        <b-col cols="12" md="6">
          <HeroDisplayText
            id="viewers"
            :value="staff.viewers_name"
            label="Viewers"
          />
        </b-col>

        <b-col cols="12" md="6">
          <HeroDisplayText
            id="seo-members"
            :value="staff.seo_members_name"
            label="SEO Members"
          />
        </b-col>

      </b-row>
    </b-col>
    <b-col cols="12">
      <hr>
    </b-col>
    <b-col cols="12">
      <b-row>
        <b-col cols="12" md="4">
          <HeroDisplayText
            id="optimizer_1_name"
            :value="staff.optimizer_1.name"
            label="Optimizer 1"
          />
        </b-col>
        <b-col cols="12" md="4">
          <HeroDisplayText
            id="optimizer_1_point"
            :value="staff.optimizer_1.ad_platform"
            label="OP1 Ad Platform"
          />
        </b-col>
        <b-col cols="12" md="4">
          <HeroDisplayText
            id="optimizer_1_point"
            :value="staff.optimizer_1.user_level_points_display"
            label="OP1 Point"
          />
        </b-col>

        <b-col cols="12" md="4">
          <HeroDisplayText
            id="optimizer_2_name"
            :value="staff.optimizer_2.name"
            label="Optimizer 2"
          />
        </b-col>
        <b-col cols="12" md="4">
          <HeroDisplayText
            id="optimizer_2_point"
            :value="staff.optimizer_2.ad_platform"
            label="OP2 Ad Platform"
          />
        </b-col>
        <b-col cols="12" md="4">
          <HeroDisplayText
            id="optimizer_2_point"
            :value="staff.optimizer_2.user_level_points_display"
            label="OP2 Point"
          />
        </b-col>

        <b-col cols="12" md="4">
          <HeroDisplayText
            id="optimizer_3_name"
            :value="staff.optimizer_3.name"
            label="Optimizer 3"
          />
        </b-col>
        <b-col cols="12" md="4">
          <HeroDisplayText
            id="optimizer_3_point"
            :value="staff.optimizer_3.ad_platform"
            label="OP3 Ad Platform"
          />
        </b-col>
        <b-col cols="12" md="4">
          <HeroDisplayText
            id="optimizer_3_point"
            :value="staff.optimizer_3.user_level_points_display"
            label="OP3 Point"
          />
        </b-col>

        <b-col cols="12" md="4">
          <HeroDisplayText
            id="optimizer_4_name"
            :value="staff.optimizer_4.name"
            label="Optimizer 4"
          />
        </b-col>
        <b-col cols="12" md="4">
          <HeroDisplayText
            id="optimizer_4_point"
            :value="staff.optimizer_4.ad_platform"
            label="OP4 Ad Platform"
          />
        </b-col>
        <b-col cols="12" md="4">
          <HeroDisplayText
            id="optimizer_4_point"
            :value="staff.optimizer_4.user_level_points_display"
            label="OP4 Point"
          />
        </b-col>

        <b-col cols="12" md="4">
          <HeroDisplayText
            id="optimizer_5_name"
            :value="staff.optimizer_5.name"
            label="Optimizer 5"
          />
        </b-col>
        <b-col cols="12" md="4">
          <HeroDisplayText
            id="optimizer_5_point"
            :value="staff.optimizer_5.ad_platform"
            label="OP5 Ad Platform"
          />
        </b-col>
        <b-col cols="12" md="4">
          <HeroDisplayText
            id="optimizer_5_point"
            :value="staff.optimizer_5.user_level_points_display"
            label="OP5 Point"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'

export default {
  components: {
    HeroDisplayText,
  },
  props: {
    staff: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
}
</script>
