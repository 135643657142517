<template>
  <app-collapse accordion type="border">
    <b-tab title="Campaign">
      <Buttons @upsellSuccess="(isSuccess) => upsell_success = isSuccess" @importSuccess="(isSuccess) => import_success = isSuccess" @autoSyncSuccess="() => autosync_success = !autosync_success" />
      <MediaPlan
        :upsell-success="upsell_success"
        :import-success="import_success"
        :tab-index="tabIndex"
      />
      <CampaignOverview :auto-sync-success="autosync_success" />
      <OpportunityHistory />
    </b-tab>
  </app-collapse>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import CampaignOverview from './Campaign/CampaignOverview.vue'
import Buttons from './Campaign/Buttons.vue'
import MediaPlan from './Campaign/MediaPlan.vue'
import OpportunityHistory from './Campaign/OpportunityHistory.vue'

export default {
  components: {
    AppCollapse,
    Buttons,
    CampaignOverview,
    MediaPlan,
    OpportunityHistory,
  },
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      upsell_success: false,
      import_success: false,
      autosync_success: false,
    }
  },
}
</script>
